<template lang="pug">
ui-dialog(
  closable
  width="680px"
  :header="getHeaderLabel()"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#content)
    alert-message(v-if="showAlert" hide-icon mini type="warning" label="Авторизованным пользователям доступна возможность самостоятельно сформировать счет на оплату некоторых тарифов")
    order-form(
      show-textarea
      v-model="form"
      ref="orderFormRef"
      @confirm="createOrder(serviceId, getSystemComment(), metric)"
    )
  template(#actions)
    .agreement-wrapper
      agreement(mode="agreement")
      ui-button.order-button(:loader="isSaving" @click.stop="validate") Заказать
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useOrderForm } from "@/use/tenderService/useOrderForm";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import UiButton from "@/components/ui/button/UiButton.vue";
import UiDialog from "@/components/ui/dialog/UiDialog.vue";
import OrderForm from "@/components/orderForm/OrderForm.vue";
import Agreement from "@/components/authorization/dialogContent/Agreement.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import { TariffsListConst } from "~/const/tariffs";

export default defineComponent({
  name: "TariffOrderDialog",
  components: {
    AlertMessage,
    UiButton,
    Agreement,
    UiDialog,
    OrderForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tariff: {
      type: String,
      default: 'Я - Специалист',
    },
    tariffId: {
      type: Number,
      default: 0,
    },
    period: {
      type: Number,
      default: 0,
    },
    serviceId: {
      type: Number,
      default: 0,
    },
    metric: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const { isAuth } = useUserAccess();

    function hide() {
      context.emit('update:show', false)
    }

    function getHeaderLabel() {
      if (props.serviceId === 23) {
        return `Заказ тарифного плана "${props.tariff}"` + (props.period !== 0 ? ` на ${props.period} ${props.period === 1 ? 'месяц' : 'месяцев'}` : '')
      } else {
        return `Дополнительные предложения: ${props.tariff}`
      }
    }

    function getSystemComment() {
      return props.serviceId === 23 ? getHeaderLabel() : '';
    }

    const showAlert = computed(() => !isAuth.value && props.serviceId === 23 && TariffsListConst.find(e => e.id === props.tariffId)?.isCostFinal)

    const {
      form,
      isSaving,
      orderFormRef,
      validate,
      createOrder,
    } = useOrderForm(hide);

    return {
      form,
      isSaving,
      orderFormRef,
      showAlert,
      validate,
      hide,
      createOrder,
      getHeaderLabel,
      getSystemComment,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/forms/orderForm";
</style>
