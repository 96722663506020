<template lang="pug">
.outer(:class="isAuth && 'fit-width'")
  .inner-row
    .promotion
      .promo-title {{ promo.promoTitle }}
      .promo-description(v-if="promo.promoDescription") {{ promo.promoDescription }}
      .promo-agreement(v-if="promo.promoAgreement") {{ promo.promoAgreement }}
    .pic-container
      img.pic(src="/pics/landing/discount.svg")
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import type { PromoI } from "~/use/other/usePromo";

export default defineComponent({
  name: "Promotion",
  props: {
    promo: {
      type: Object as PropType<PromoI>,
      default: () => ({}),
    },
  },
  setup() {

    const { isAuth } = useUserAccess();

    return {
      isAuth,
    }
  }
})
</script>

<style scoped lang="scss">
$background-color: #d9ecff;

.promotion {
  display: flex;
  flex-flow: column;
  gap: 16px;

  border-radius: 6px;
  //background: linear-gradient(45deg, #ffd9d9, #dee8ff);
  background: $background-color;
  max-width: 1140px;

  color: var(--main-color-black);
  width: 100%;
  padding: 32px 0;
  box-sizing: border-box;
  //align-items: center;

  .promo-title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .promo-description {
    font-size: 16px;
    line-height: 20px;
  }

  .promo-agreement {
    font-size: 12px;
  }
}

.outer {
  width: 100vw;
  display: flex;
  align-self: center;
  background: $background-color;
  justify-content: center;
  margin-top: 60px;

  &.fit-width {
    width: 100%;

    .inner-row {
      padding: 0 24px;
    }

    .promotion {
      .promo-title {
        font-size: 20px;
      }
    }
  }

  .pic {
    width: 420px;
    position: absolute;
    bottom: 0;
  }

  .pic-container {
    position: relative;
  }
}

.inner-row {
  display: grid;
  grid-template-columns: 1fr 420px;
  gap: 40px;
  width: 100%;
  max-width: 1140px;
}

@media (max-width: 860px) {
  .promotion {
    .promo-title {
      font-size: 20px;
    }

    .promo-description {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
