<template lang="pug">
.tariff-switcher
  .tariff-switcher__elem(
    v-for="period of TariffsPeriodConst"
    :key="period.duration"
  )
    ui-button(:type="selectedPeriod === period.duration ? 'primary' : 'secondary'" @click="selectPeriod(period.duration, period.discount)") {{ period.durationLabel }}
    sup.badge(v-if="period.discountLabel") {{ period.discountLabel }}
</template>

<script lang="ts">
import { ref } from "vue";
import { TariffsPeriodConst } from "~/const/tariffs";

import UiButton from "@/components/ui/button/UiButton.vue";

export default {
  name: "TheTariffSwitcher",
  emits: [
    "setDiscount",
  ],
  components: {
    UiButton,
  },
  setup(props: any, context: any) {

    const selectedPeriod = ref(TariffsPeriodConst[0].duration)

    function selectPeriod(month: number, discount: number) {
      selectedPeriod.value = month
      context.emit('setDiscount', { discount: discount, period: month })
    }

    return {
      selectedPeriod,
      selectPeriod,
      TariffsPeriodConst,
    }
  }
}
</script>

<style scoped lang="scss">
.tariff-switcher {
  display: flex;
  gap: 16px;
  flex-flow: row wrap;
  justify-content: center;
  padding: 8px;
  align-items: center;

  ::v-deep(.ui-button) {
    width: 144px;
    justify-content: center;
  }

  .tariff-switcher__elem {
    position: relative;
  }

  .badge {
    right: -16px;
    z-index: 1;
    top: -9px;
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    background-color: #f56c6c;
    color: var(--main-color-white);
    border-radius: 25px;
    padding: 2px 8px;
  }
}
</style>
